<template>
    <div >
        <Loading v-if="loadingActive"/>
        <InfoModal v-if="modalActive" :modal-message="modalMessage" v-on:close-modal="closeModal"/>
        <b-container>
            <b-row class="d-flex justify-content-center align-items-center py-2" v-if="user.branch === 'Maesot'" >
                <b-col cols="12" lg="8" md="10" class="mt-5 pt-5">
                    <div class="div-card p-2">
                        <div class="">
                            <div class="d-flex justify-content-between align-items-center div-card px-2 m-1 bg-light">
                                <span class="fw-bolder fs-3">Create Parcel</span>
                                <button class="mt-3 btn btn-outline-dark btn-sm my-2" type="reset" @click="clearForm">Reset</button>
                            </div>
                        </div>
<!--                        Parcel Create Form-->
                        <div>
                            <form @submit.prevent="insertParcel" autocomplete="off">
                                <div class="row">
                                    <div class="col-12 col-md-6 col-lg-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" id="ms-code" v-model="form.msCode" class="form-control" required placeholder="Enter Ms Code">
                                            <label for="ms-code">Ms Code</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" id="order-number" v-model="form.orderNumber" class="form-control" required  placeholder="Enter Order number">
                                            <label for="order-number">Order Number</label>
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 col-lg-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" id="name" v-model="form.name" class="form-control" required  placeholder="Enter customer name">
                                            <label for="name">Customer Name</label>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-6 col-lg-6">
                                        <div class="form-floating mb-3">
                                            <input type="number" id="phone" v-model="form.phone" class="form-control" required  placeholder="Enter phone number">
                                            <label for="phone">Customer Phone</label>
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 col-lg-6">
                                        <select class="form-select mb-3" v-model="form.city" required aria-label="default select example">
                                            <option value="" selected disabled >Send To: </option>
                                            <option v-for="(city , index) in cities" v-bind:key="index" :class="city === user.branch?'d-none':''" >
                                                {{city}}
                                            </option>
                                        </select>

                                    </div>
                                    <div class="col-12 col-md-6 col-lg-6">
                                      <input type="date" v-model="form.date" class="form-control" required>
                                     </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 col-lg-6 mx-auto">
                                        <button class= "btn btn-dark w-100 m-1" type="submit">Add</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <div class="row">
                <div class="d-lg-flex mb-2 d-md-flex justify-content-between align-items-center" :class="user.branch !== 'Maesot' ? 'mt-5 pt-5':''">
                    <div>
                        <router-link :to="{name:'ParcelLists'}" class="fw-bolder btn btn-outline-dark m-2 btn-sm fs-3" title="View All Parcels"><i class="fa fa-list"></i> </router-link>
                        <span class="fs-4 fw-bolder">Today Parcel Lists "{{this.todayDate}}"</span>
                    </div>
                    <div class="d-flex ">
                        <input type="text" v-model="search" class="form-control"  placeholder="Search...">

                    </div>
                </div>
                <div class="col-12 mb-5 table-responsive">
                    <table class="table table-hover table-striped border">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th class="text-nowrap">Customer Name</th>
                            <th class="text-nowrap">MS Code</th>
                            <th class="text-nowrap">Order Number</th>
                            <th class="text-nowrap">Phone</th>
<!--                            <th class="text-nowrap">Group</th>-->
                            <th class="text-nowrap">Current City</th>
                            <th class="text-nowrap">To</th>
                            <th class="text-nowrap">Status</th>
                            <th class="text-nowrap">Action</th>
                          <th class="text-nowrap">Date</th>
                            <!--                                <th class="text-nowrap">Date</th>-->
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(parcel, index) in filterParcels" :key="parcel.parcelID" class="align-middle text-start" >
                            <td>{{index+1}}</td>
                            <td>{{parcel.name}}</td>
                            <td>{{parcel.msCode}}</td>
                            <td>{{parcel.orderNumber}}</td>
                            <td>{{parcel.phone}}</td>
<!--                            <td class="text-nowrap">{{parcel.parcelGroup}}</td>-->
                            <td class="text-nowrap" v-if="parcel.changedCity">{{parcel.changedCity?parcel.changedCity:user.branch}}</td>
<!--                            <td class="text-nowrap" v-else>{{branch}}</td>-->
                            <td class="text-nowrap">{{parcel.to}}</td>
                            <!--                                <td class="text-nowrap">{{parcel.parcelCity}}</td>-->
                            <td><span class="badge rounded-pill bg-primary" :class="parcel.to===parcel.changedCity ? 'bg-success':'bg-warning'" >{{parcel.to===parcel.changedCity?"Arrived":"On going"}}</span></td>
                            <td class="text-nowrap">
                                <div class="d-flex">
                                    <button class="btn btn-outline-primary btn-sm " @click="showParcel(parcel.id)" :class="user.branch==='Maesot'?'d-none':''"><i class="fa fa-info-circle"></i></button>
                                    <button class="btn btn-outline-warning btn-sm mx-2"  @click="editParcel(parcel.id)" v-if="user.branch === 'Maesot'"><i class="fa fa-edit"></i></button>
                                    <!-- <button v-if="this.$store.state.user.email==='admin@gmail.com'" class="btn btn-outline-danger btn-sm" @click="parcelDelete(parcel.parcelID)"><i class="fa fa-trash"></i></button>-->
                                </div>

                            </td>
                          <td>{{parcel.filterDate}}</td>
                            <!-- <td class="text-nowrap">{{parcel.parcelDate.toDate().toLocaleTimeString('en-US',{ day:"numeric",month:"short", year:"numeric", })}}</td>-->
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </b-container>
    </div>
</template>

<script>
import db, {timestamp} from "../../firebaseConfig";
import "firebase/auth";
import Loading from "../../components/Loading";
import InfoModal from "../../components/InfoModal";
export default {
  name:'CreateParcel',
  components: {Loading, InfoModal},
  data(){
    return{
      multi:false,
      loadingActive:false,
      modalMessage:"",
      modalActive:false,
      search:"",
      parcels:"",
      translate:"en", //for date picker
      form:{
        msCode:"",
        orderNumber:"",
        name:"",
        phone:"",
        city:"",
        group:"",
        date:"",
      },

    }
  },

  created() {
   /* this.$store.dispatch("getTodayParcelLists")
        .catch(err=>{
          this.$router.push({name:'ErrorDisplay', params:{error:err}})
        })*/
  },
  mounted(){
    this.$bind('parcels', db.collection('parcelLists').where("filterDate","==",this.todayDate).orderBy("date","desc"))
  },
  computed:{
    todayDate(){
      const today = new Date();
      const currentDate =  today.getFullYear()+"-"+(today.getMonth() + 1).toString().padStart(2, "0")+'-'+today.getDate().toString().padStart(2,"0");
      return currentDate;
    },
    user(){
      return this.$store.state.user;
    },

    /*parcels(){

      return this.$store.state.todayParcelLists;
    },*/

    filterParcels(){
      if(this.search!==""){
        return this.parcels.filter((parcel)=>{
          return parcel.group.includes(this.search) || parcel.msCode.includes(this.search) || parcel.orderNumber.includes(this.search) || parcel.phone.includes(this.search);
        });
      }else {
        return this.parcels;
      }
    },


    cities(){
      return this.$store.state.cities;
    },

  },

  methods:{
    formatDate(date) {
      return date.toLocaleDateString();
    },
    closeModal(){
      this.modalActive = !this.modalActive;
    },

    async insertParcel(){
      this.loadingActive = true;
     if(this.form.msCode !== null &&
          this.form.orderNumber!== null &&
          this.form.name !== null &&
          this.form.phone !== null &&
         this.form.date !== null &&
          this.city!=='')
      {
        const parcelDB = await db.collection('parcelLists').doc();
        const parcelID = parcelDB.id;
        parcelDB.set({
          data:parcelID,
          msCode:this.form.msCode,
          orderNumber:this.form.orderNumber,
          name:this.form.name,
          phone:this.form.phone,
          userId:this.user.uid,
          to:this.form.city,
          branch:this.user.branch,
          group:"",
          changedCity:this.user.branch,
          date:timestamp,
          updatedAt:timestamp,
          filterDate:this.form.date
        }).then(()=>{
          this.loadingActive=false;
          const updateData = db.collection("parcelLists").doc(parcelID).collection('record').doc();
          updateData.set({
            date:timestamp,
            process:"firstAdded",
            id:updateData.id,
            user:this.user.email,
            branch:this.user.branch,
          }).then(()=>{
            this.loadingActive = false;
            this.modalActive = true;
            this.modalMessage = "Added Successfully";
            // this.$store.dispatch("getTodayParcelLists");
            this.form.msCode = "";
            this.form.orderNumber="";

          }).catch(err=>{
            this.loadingActive = false;
            this.modalActive = true;
            this.modalMessage = err.message;
          });
        }).catch((err)=>{
          this.loadingActive = false;
          this.modalMessage = err.message;
          this.modalActive = true;
        });
      }
      else {
        this.loadingActive = false;
        this.modalMessage = "Please fill completely!";
        this.modalActive = true;
      }

    },

    clearForm(){
      this.form.msCode="";
      this.form.orderNumber="";
      this.form.phone="";
      this.form.name="";
      this.form.group="";
    },

    showParcel(id){
      this.$router.push({name:"AllParcelGroupDetails", params:{parcelID:id}})
    },

    editParcel(id){
      this.$router.push({name:"UpdateParcel",params:{parcelID:id}});

    },

    parcelDelete(id){
      if (confirm("Are you sure to delete!") == true) {
        if(this.$store.state.user.id === "uVjJeMcKhedaDrBTeRFdjoXxQIx1"){
          this.$store.dispatch("deleteParcel", id);
        }else {
          this.modalActive = true;
          this.modalMessage = "No permission to delete!"
        }
        // this.$store.dispatch("getParcelLists");
      }

    }
  }
}

</script>

<style scoped>
    input, select, button, option, .card, .card-header{
        border-radius: 20px !important;
    }
    label{
        color: rgba(108, 106, 106, 0.84);
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    .form-floating{
        padding: 0 !important;

    }
    .div-card{
        border: 2px solid #f7f7f7;
        border-radius: 20px;
    }

</style>